:root {
  --gray-000: #f8f9fa;
  --gray-100: #f1f3f5;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #868e96;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;

  --pink: #e64980;
  --grape: #be4bdb;
  --purple: #7950f2;
  --indigo: #4c6ef5;
  --blue: #072BB8;
  --cyan: #15aabf;
  --teal: #12b886;
  --green: #40c057;
  --yellow: #fab005;
  --orange: #fd7e14;

  --red: #cf352e;
  --white: #fdffff;
  --gray: #222;

  --body-font: 'Nanum Myeongjo', math, serif;
  --body-color: var(--gray);
  --body-bg: var(--white);

  --link-color: var(--gray);
  --link-hover-color: var(--blue);

  --heading-color: var(--gray);

  --border-color: var(--gray);
  --border-radius: .25rem;

  --code-font: monospace;
  --code-color: var(--grape);
  --code-bg: var(--gray-000);

  --spacer: 1rem;
  --spacer-2: calc(var(--spacer) * 1.5);
  --spacer-3: calc(var(--spacer) * 3);
  --spacer-6: calc(var(--spacer) * 6);
}