// Home
//
// Styling for home page

.container-piece {
  position: relative;
  padding: 2rem 2rem 0 2rem;
}

.masthead {
  line-height: 1.3;
  font-weight: 400;
  padding: 2rem;
}

.nav-column {
  height: 100%;
}

.nav-column ul {
  list-style: none;
  padding: 0;
  text-transform: uppercase;
}

.nav-column ul a,
.nav-column ul a:hover,
.nav-column ul a:active,
.nav-column ul a:visited {
  text-decoration: none;
  border-bottom: 0;
}

.iframe-video {
  height: 200px;
  width: 350px;
}

.ml-form {
  padding-top: 2rem;
  font-family: var(--body-font);
  font-size: 1rem;
}

.ml-form div, .ml-form form {
  display: inline;
}

.ml-form input, .ml-form input:focus {
  display: inline;
  background: var(--gray-0);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid var(--gray);
  width: 85%;
  font-family: var(--body-font);
  font-size: 1rem;
  height: 2rem;
  outline: none;
  margin-top: .5rem;
}

.ml-form input::placeholder {
  font-family: var(--body-font);
  font-size: 1rem;
}

.ml-form button {
  display: inline;
  background: var(--gray-0);
  border: 1px solid var(--gray);
  width: 2rem;
  font-family: var(--body-font);
  font-size: 1rem;
  height: 2rem;
}

#success-message {
  font-size: 1rem;
}

.small-text {
  font-size: 1rem;
  padding-top: 2rem;
}

.home-media {
  width: 100%;
  margin: 0;
  display: block;
}

.home-title {
  width: 100%;
  z-index: 999;
}

.home-title h3 {
  margin: 0;
  font-weight: 400;
  line-height: 1.3;
  padding: 4px 0;
  font-size: 1rem;
  text-transform: uppercase;
}

.container-piece-bg:hover {
  background-color: var(--blue);

  .home-title h3 {
    color: var(--body-bg);
  }
}

@media only screen and (min-width: 600px) {

  .iframe-video {
    height: 300px;
    width: 550px;
  }

}

@media only screen and (min-width: 800px) {

  .iframe-video {
    height: 450px;
    width: 750px;
  }

}

@media only screen and (min-width: 1200px) {
  body, .home-title h3 {
    font-size: 1.1rem;
  }

  .nav-column {
    position: fixed;
    left: 0;
    top: 0;
    width: 21%;
    padding: 4px 0 4px 4px;
  }
  
  .content-column {
    position: relative;
    width: 79%;
    left: 21%;
    min-height: 80vh;
  }

  .container-media {
    max-height: 90vh;
    overflow: hidden;
  }

  .iframe-video {
    height: 750px;
    width: 100%;
    padding-bottom: .5rem;
  }

}