// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. Each
// page is wrapped in `.page` and is only used on the page layout.

p {

  a {
    padding-bottom: 1px;
    border-bottom: 1px solid var(--link-color);
  }

  a:hover {
    border-bottom: 1px solid var(--link-hover-color);
  }
}

.page,
.post {
  line-height: 1.75;
  margin-bottom: 2em;
  padding-top: 6rem;

  li + li {
    margin-top: .25rem;
  }

  h1 {
    margin-right: 1rem;
  }
}

// Blog post or page title
.page-title,
.post-title,
.section-title {
  color: var(--heading-color);
  font-size: 1rem;
  margin-top: 0;
  display: inline-block;
  line-height: 1.3;
  font-weight: 400;
  text-transform: uppercase;
}

.post-title a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
    border-bottom: 4px solid var(--red);
  }
}

.post-media {
  display: inline-block;
}

// Meta data line next to post title
.post-date {
  font-size: 1rem;
  font-weight: 400;
  display: inline;
}

// Related image
.post-image-hover {
  display: none;
}

a:hover + .post-image-hover {
  display: inline;
  z-index: -999;
  position: fixed;
  top: var(--spacer-2);
  right: var(--spacer-2);
}

// Navigation
.next, .prev {
  width: 49%;
  display: inline-block;
}

.next {
  text-align: right;
}

// Related posts
.related {
  padding-top: var(--spacer-2);
  padding-bottom: var(--spacer-2);
  margin-bottom: var(--spacer-2);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.related-posts {
  padding-left: 0;
  list-style: none;

  h3 {
    margin-top: 0;
  }

  a {
    text-decoration: none;

    small {
      color: var(--gray-600);
    }
  }
}

.post .media-caption {
  text-align: center;
  width: 100%;
  font-size: 1rem;
  padding: 1rem 2rem;
}

.media-bottom .media-caption {
  padding: 1rem 2rem 4rem 2rem;
}

.media-caption {
  a {
    padding-bottom: 1px;
    border-bottom: 1px solid var(--link-color);
  }

  a:hover {
    border-bottom: 1px solid var(--link-hover-color);
  }
}

@media only screen and (min-width: 1000px) {

  .page,
  .post,
  .page-title,
  .post-title,
  .section-title {
    font-size: 1.1rem;
  }

  .post-date {
    font-size: 1.1rem;
    width: 10rem;
    margin-right: var(--spacer-2);
  }

  .media-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 1rem;
  }

  .media-row-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    text-align: center;
  }

  .media-row-item img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .media-row-item .media-caption {
    max-width: 80%;
    flex-shrink: 0;
  }

}

@media only screen and (min-width: 1300px) {
  .post-media {
    display: inline;
    float: right;
    text-align: right;
    max-width: 75%;
  }
}