.splide__slide img, .splide__slide video, .splide__slide iframe {
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
}

.splide__slide iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.splide__slide {
  opacity: 0.6;
}

.splide__slide.is-active {
  opacity: 1;
}

.post li+li {
  margin-top: 0;
}

#thumbnail-carousel {
  margin-bottom: 2rem;
}

#thumbnail-carousel .splide__list {
  justify-content: center;
}

#thumbnail-carousel .splide__list li {
  height: 125px !important;
}

.carousel_caption {
  z-index: 999;
  display: block;
  margin-top: -20px;
}