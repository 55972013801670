// Layout
//
// Styles for managing the structural hierarchy of the site.

.container {
  width: 100%;
  margin-left:  auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}

.container-row {
  width: 100%;
  margin-left:  auto;
  margin-right: auto;
}

.container-80 {
  padding: 2rem 0;
  width: 80%;
  margin-left:  auto;
  margin-right: auto;
}

.container-60 {
  padding: 2rem;
  margin-left:  auto;
  margin-right: auto;
}

.container-v-padding {
  padding: 0;
}

@media only screen and (min-width: 1000px) {

  .column-2 {
    column-count: 2;
    column-gap: 4rem;
    break-inside: avoid-column;

    img {
      padding-bottom: 4rem;
    }

    .column-section {
      break-inside: avoid-column;
    }
  }

  .container-60 {
    padding: 2rem 0;
    width: 70%;
  }

  .container-v-padding {
    padding: 2rem 0;
  }

  .top-nav {
    font-size: 1.1rem;

    ul {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }

    li {
      display: inline;
      width: 33%;
    }
  }

  footer {
    font-size: 1.1rem;
    // font-size: 1rem;
  }

  .container-row img.padded {
    width: 60%;
  }

}

.container-row-wrapper {
  flex: 1;
}

.container-row img {
  object-fit: contain;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container-50 {
  width: 50%;
  padding-left: var(--spacer-3);
  padding-right: var(--spacer-3);
  position: relative;
}

.container-table {
  display: table;
  height: 100%;
  overflow: hidden;
}

.container-table-cell {
  display: table-cell; 
  vertical-align: middle;
}

.top-nav {
  position: fixed;
  display: block;
  z-index: 9999;
  background-color: var(--body-bg);
  width: 100%;
  padding: 1rem;
  margin-bottom: 3rem;
  margin-left:  auto;
  margin-right: auto;
  letter-spacing: 0.33px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--gray);

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  li {
    display: inline;
    width: 33%;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }
}

footer {
  margin-top: 2rem;
  padding: 3rem 0;
  text-align: center;
  letter-spacing: 0.33px;
  border-top: 1px solid var(--gray);
}
