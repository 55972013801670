// Body resets
//
// Update the foundational and global aspects of the page.

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--body-font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  color: var(--body-color);
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

// No `:visited` state is required by default (browsers will use `a`)
a {
  text-decoration: none;
  color: var(--link-color);

  // `:focus` is linked to `:hover` for basic accessibility
  &:hover,
  &:focus {
    color: var(--link-hover-color);
  }

  strong {
    color: inherit;
  }
}

img {
  display: block;
  max-width: 100%;
  margin-bottom: var(--spacer);
}

table {
  margin-bottom: 1rem;
  width: 100%;
  border: 0 solid var(--border-color);
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
}

th {
  text-align: left;
}

thead th {
  border-bottom-color: currentColor;
}

mark {
  padding: .15rem;
  background-color: var(--yellow-100);
  border-radius: .125rem;
}

@font-face {
  font-family: "Nanum Myeongjo";
  src: url("assets/nanum_myeongjo/NanumMyeongjo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Nanum Myeongjo";
  src: url("assets/nanum_myeongjo/NanumMyeongjo-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
